 
import React from 'react'
import { Swiper} from 'antd-mobile'
import  "./index.scss";

const Atest = () => {

    const colors = ['#ace0ff', '#bcffbd', '#e4fabd', '#ffcfac']
    const verticalItems = colors.map((color, index) => (
        <Swiper.Item key={index}>
          <div style={{ background: color}} className='content'>
            {index + 1}
          </div>
        </Swiper.Item>
      ))
      
    return <>
     

    <Swiper direction='vertical' className='content' indicator={() => null}>
          {verticalItems}
        </Swiper>
        666666

    </>
}

export default Atest;