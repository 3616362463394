import Atest from '@/pages/Atest';
import M1 from "@/pages/M1";
import Y2 from "@/pages/Y2";
import Y1 from "@/pages/Y1";
import E404 from "@/pages/E404";
import Y1menu from "@/pages/Y1menu";

import Murl from "@/pages/Murl";
import { createBrowserRouter } from 'react-router-dom';


const router = createBrowserRouter([
    {
        path: "/",
        element: <M1 />, children: [
            {
                path: "f",
                element: <Y1></Y1>,
                children: [
                {
                    path: "index",
                    element: <Y1menu />,
                },
                {
                    path: "*",
                    element: <E404 />
                }

                ],
            },
            {
                path: "s",
                element: <Y2></Y2>
            }
            ,{
                path: "",
                element: <Atest></Atest>
            }
            
        ]
    },
     {
         path: "/:dbno/:dbtype/:dbcode",
         element: <Murl />
     },
])
export default router